import React, { useState } from "react";
import "./App.css";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import StartIcon from "@mui/icons-material/Start";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { Tweet } from "react-tweet";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";

function App() {
    const theme = createTheme({
        typography: {
            button: {
                textTransform: "none",
            },
        },
    });
    return (
        <>
            <ThemeProvider theme={theme}>
                <Box sx={{ display: "flex" }}>
                    <Header />
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            p: 3,
                            width: { sm: "100%" },
                        }}
                    >
                        <Toolbar />
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Top />} />
                                <Route
                                    path="/*"
                                    element={
                                        <>
                                            <Typography variant="h3">
                                                ページが見つかりませんでした。
                                            </Typography>
                                            <p>
                                                URLが正しいか確認してください。
                                            </p>
                                        </>
                                    }
                                />
                            </Routes>
                        </BrowserRouter>
                    </Box>
                </Box>
            </ThemeProvider>
        </>
    );
}

function Header() {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed">
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        ツイートソース確認ツール
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

interface sourceResult {
    source: string;
}

function Top() {
    const [URL, setURL] = useState<string>("");
    const [errormsg, setErrormsg] = useState<string>("");
    const [Loading, setLoading] = useState<boolean>(false);
    const [result, setResult] = useState<string>("");
    const [tweetID, setTweetID] = useState<string>("");

    const checkSource = () => {
        setResult("");
        setErrormsg("");
        var status_id_str: string | null = null;
        if (
            URL.includes("https://twitter.com/") ||
            URL.includes("https://mobile.twitter.com/") ||
            URL.includes("https://x.com/") ||
            URL.includes("https://mobile.x.com/")
        ) {
            let question_index = URL.indexOf("?");
            if (question_index > 0) {
                let st_index = URL.indexOf("status/") + 7;
                let q_index = URL.indexOf("?");
                status_id_str = URL.substring(st_index, q_index);
                if (isNaN(Number(status_id_str))) {
                    setErrormsg("正しいURLを入力してください。");
                    return;
                }
            } else {
                let st_index = URL.indexOf("status/") + 7;
                status_id_str = URL.slice(st_index);
                if (isNaN(Number(status_id_str))) {
                    setErrormsg("正しいURLを入力してください。");
                    return;
                }
            }
        }
        if (status_id_str == null) {
            setErrormsg("正しいURLを入力してください。");
            return;
        }
        setTweetID(status_id_str);
        setLoading(true);
        const instance = axios.create({
            // ベースURLやタイムアウトなどのオプションを設定する
            baseURL: "https://api.irucabot.com/ReturnTweetSourceLabel/",
            timeout: 10000,
        });
        // HTTPリクエストを送る
        instance
            .get<sourceResult>(`getsource?tweet=${URL}`)
            .then((response) => {
                setLoading(false);
                setResult(response.data.source);
            })
            .catch((error) => {
                setLoading(false);
                setErrormsg(
                    "エラーが発生しました。ツイートが存在しないか、非公開の可能性があります。"
                );
            });
    };

    return (
        <div style={{ textAlign: "center" }}>
            <Typography variant="h4" gutterBottom>
                ツイートソース確認ツール
            </Typography>
            <p>以下にツイートのURLを入力するとツイートソースがわかります。</p>
            <TextField
                id="outlined-basic"
                label="ツイートURL"
                variant="outlined"
                sx={{ maxWidth: "500px", width: "90%" }}
                value={URL}
                onChange={(e) => {
                    setURL(e.target.value);
                }}
                type="url"
                error={errormsg !== ""}
                helperText={errormsg !== "" && errormsg}
            />
            <div style={{ padding: "10px" }}></div>
            <LoadingButton
                loading={Loading}
                loadingPosition="start"
                startIcon={<StartIcon />}
                variant="contained"
                sx={{ maxWidth: "500px", width: "90%" }}
                onClick={checkSource}
            >
                確認する
            </LoadingButton>
            {result !== "" && (
                <>
                    <div style={{ padding: "20px" }}></div>
                    <Typography variant="h5" gutterBottom>
                        結果: <strong>{result}</strong>
                    </Typography>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "left",
                        }}
                    >
                        <Tweet id={tweetID} />
                    </div>
                </>
            )}
            <div style={{ padding: "20px" }}></div>
            <Typography variant="h5" gutterBottom>
                ツイートソースとは？
            </Typography>
            <p>
                どのデバイスや環境からツイートされたかを示すもので、例えばiPhone,
                Android,
                Webなどです。botによる自動ツイートの場合はそのbotが使用しているアプリケーションの名前が表示されます。
            </p>
            <p>
                以前はツイートの日付の隣に表示されていましたが、現在は表示されなくなっています。
            </p>
            <div style={{ padding: "20px" }}></div>
            <Divider variant="middle" />
            <div style={{ padding: "20px" }}></div>
            <Typography variant="h4" gutterBottom>
                拡張機能
            </Typography>
            <p>
                ツイートソースラベルを表示する拡張機能もあります。以前のTwitterと同じように表示されます。
            </p>
            <Link
                component="a"
                href="https://chromewebstore.google.com/detail/return-tweet-source-label/mhkngabolhbckfjpmjclhockpdclfppc?hl=ja&authuser=0"
                target="_blank"
                rel="noopener noreferrer"
            >
                <h3>Chrome</h3>
            </Link>
            <Link
                component="a"
                href="https://addons.mozilla.org/ja/firefox/addon/return-tweet-source-label/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <h3>Firefox</h3>
            </Link>
            <div style={{ padding: "40px" }}></div>
            <Link
                component="a"
                href="https://irucabot.com"
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
            >
                © Copyright: irucabot.com
            </Link>
        </div>
    );
}

export default App;
